import React from 'react';
import { IconTypes } from '../../../models/Icons/IconModels';

export const IconGraph = ({ active = false }: IconTypes) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.7266 9.48241C44.4434 9.5996 44.0723 9.93163 43.916 10.2051C43.8281 10.3516 43.7891 14.5801 43.75 26.582C43.7012 42.7344 43.7012 42.7637 43.4961 43.0273C43.3887 43.1738 43.1738 43.3887 43.0273 43.4961L42.7637 43.7012L23.4375 43.75L4.11133 43.7988L3.84766 44.0039C3.33008 44.3848 3.17383 44.6973 3.17383 45.3125C3.17383 45.9277 3.33008 46.2402 3.84766 46.6211L4.11133 46.8262L23.3203 46.8555C35.9766 46.8652 42.7051 46.8457 43.0566 46.7773C44.873 46.4453 46.4551 44.8633 46.7773 43.0664C46.8457 42.7148 46.8652 36.8848 46.8555 26.4453C46.8262 10.3809 46.8262 10.3613 46.6211 10.0976C46.5137 9.95116 46.3086 9.73632 46.1719 9.63866C45.8691 9.41405 45.0879 9.32616 44.7266 9.48241Z"
        fill="#FF6633"
      />
      <path
        d="M32.1777 19.5312C32.0703 19.5703 31.8848 19.6973 31.7774 19.8047C31.6602 19.9121 30.0195 22.2461 28.125 24.9902C26.2305 27.7344 24.6485 29.9805 24.5996 29.9805C24.5508 29.9805 22.8516 28.7793 20.8203 27.3144C18.7988 25.8496 16.9238 24.5019 16.6602 24.3164C16.25 24.0234 16.1133 23.9746 15.625 23.9746C15.1953 23.9746 14.9805 24.0234 14.7754 24.1797C14.6289 24.2969 11.9629 27.5391 8.8672 31.3867C4.60939 36.6699 3.21291 38.4668 3.16408 38.7207C2.959 39.8047 3.82814 40.7422 4.90236 40.5957C5.10744 40.5664 5.38087 40.4785 5.51759 40.3906C5.64455 40.3125 8.01759 37.4121 10.8008 33.9648C13.5742 30.5078 15.8594 27.666 15.8789 27.6465C15.8985 27.6269 17.8027 28.9941 20.127 30.6738C24.1895 33.6133 24.3652 33.7305 24.8535 33.7695C25.8399 33.8476 25.6836 34.0234 29.6485 28.291C31.6113 25.4297 33.2715 23.0371 33.3399 22.9687C33.4375 22.8711 33.9942 23.0469 36.1621 23.8281C37.6465 24.3652 38.9551 24.8047 39.082 24.8047C39.4629 24.7949 40.0977 24.4531 40.332 24.1211C40.6738 23.6426 40.7129 22.9004 40.4102 22.4707C40.293 22.2851 40.0781 22.0605 39.9414 21.9531C39.8047 21.8555 38.2324 21.25 36.4453 20.6055C33.2031 19.4336 32.7051 19.3066 32.1777 19.5312Z"
        fill="#FF6633"
      />
    </svg>
  );
};
