import React from 'react';
import { IconTypes } from '../../../../models/Icons/IconModels';

export const IconArrow = ({ active = false, size = 10 }: IconTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 10 10`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.541013 2.16993C0.339841 2.2168 0.156247 2.3711 0.0644501 2.56836C0.0195283 2.66797 0.00976264 2.71094 0.0117158 2.85157C0.0117158 3.16407 -0.175784 2.95313 2.33984 5.47266C3.85742 6.99219 4.60547 7.72852 4.66601 7.76172C4.80859 7.83789 4.97461 7.86524 5.12304 7.83399C5.18945 7.82032 5.28515 7.78711 5.33398 7.76172C5.39453 7.72852 6.14258 6.99219 7.66015 5.47266C10.1758 2.95313 9.98828 3.16407 9.98828 2.85157C9.98828 2.71094 9.98047 2.66797 9.93359 2.56446C9.76953 2.20508 9.33203 2.05274 8.97461 2.23047C8.92383 2.25586 8.21679 2.94922 6.94922 4.21485L5 6.16211L3.05078 4.21485C1.77734 2.94336 1.07617 2.25586 1.02539 2.23047C0.87695 2.15821 0.693356 2.13477 0.541013 2.16993Z"
        fill="currentColor"
      />
    </svg>
  );
};
