import React from 'react';
import { IconTypes } from '../../../../models/Icons/IconModels';

export const IconStats = ({ active = false }: IconTypes) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67969 0.0410156C5.56641 0.164062 4.28906 0.632812 3.36914 1.24805C2.88281 1.57617 2.73633 1.69922 2.17969 2.24414C1.17188 3.23438 0.462891 4.55273 0.140625 6.03516C0.0351562 6.50977 0.0292969 6.94922 0.0292969 15C0.0292969 23.0508 0.0351562 23.4902 0.140625 23.9648C0.802734 26.9824 3.01758 29.1973 6.03516 29.8594C6.50977 29.9648 6.94922 29.9707 15 29.9707C23.0508 29.9707 23.4902 29.9648 23.9648 29.8594C26.9824 29.1973 29.1973 26.9824 29.8594 23.9648C29.9648 23.4902 29.9707 23.0508 29.9707 15C29.9707 6.94922 29.9648 6.50977 29.8594 6.03516C29.2734 3.35156 27.3867 1.21289 24.873 0.392578C23.7129 0.0117188 24.416 0.0351562 15.2344 0.0292969C10.6406 0.0234375 6.79102 0.0292969 6.67969 0.0410156ZM23.4961 2.60156C24.0469 2.71875 24.7559 3.00586 25.1895 3.29297C26.3262 4.03711 27.1758 5.29688 27.416 6.5918C27.4629 6.82617 27.4805 9.46289 27.4805 15.0234C27.4805 21.6855 27.4629 23.1855 27.3984 23.4961C27.2051 24.4102 26.7129 25.3242 26.0566 26.0039C25.3945 26.6836 24.2988 27.2637 23.3672 27.4219C23.1094 27.4629 20.4609 27.4805 14.7656 27.4688L6.5332 27.4512L5.97656 27.2578C5.20312 26.9883 4.57617 26.6016 4.01367 26.0449C3.26367 25.3066 2.8125 24.4922 2.60156 23.4961C2.48438 22.9277 2.48438 7.07227 2.60156 6.50391C3.01758 4.55273 4.52344 3.03516 6.47461 2.60742C7.00781 2.49023 22.9453 2.48438 23.4961 2.60156Z"
        fill={active ? '#fff' : '#333'}
      />
      <path
        d="M14.5312 7.59371C14.2676 7.7109 14.0215 7.93941 13.8809 8.2148C13.7754 8.42574 13.7695 8.65425 13.7695 15C13.7695 21.3457 13.7754 21.5742 13.8809 21.7851C14.373 22.7402 15.6738 22.7285 16.1191 21.7675C16.2246 21.5449 16.2305 21.2343 16.2305 15C16.2305 8.76558 16.2246 8.45504 16.1191 8.23238C15.8379 7.62886 15.123 7.3359 14.5312 7.59371Z"
        fill={active ? '#fff' : '#ff6633'}
      />
      <path
        d="M8.44922 12.539C8.11523 12.6152 7.81641 12.8613 7.65234 13.1894L7.5 13.5V17.455C7.5 20.0859 7.52344 21.4922 7.56445 21.6445C7.65234 21.9609 8.05078 22.3418 8.39648 22.4355C8.95313 22.5937 9.53906 22.3593 9.82617 21.8672L9.99023 21.5918L10.0078 17.6953C10.0195 14.9707 10.0078 13.705 9.96094 13.4941C9.86719 13.0664 9.52734 12.6855 9.12305 12.5683C8.80078 12.4746 8.73633 12.4687 8.44922 12.539Z"
        fill={active ? '#fff' : '#ff6633'}
      />
      <path
        d="M20.9062 12.5508C20.7832 12.58 20.5664 12.7148 20.4199 12.8437C19.9629 13.248 19.9746 13.1191 19.9922 17.6425L20.0098 21.5918L20.1738 21.8672C20.4609 22.3593 21.0469 22.5937 21.6035 22.4355C21.9492 22.3418 22.3301 21.9785 22.4297 21.6445C22.4824 21.4629 22.5 20.4082 22.5 17.4433V13.4883L22.3359 13.1777C22.0664 12.6562 21.4629 12.3925 20.9062 12.5508Z"
        fill={active ? '#fff' : '#ff6633'}
      />
    </svg>
  );
};
