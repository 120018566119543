import React from 'react';
import { IconTypes } from '../../../models/Icons/IconModels';

export const IconLogout = ({ active = false }: IconTypes) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.19922 1.4707C2.83594 1.55859 2.30273 1.86914 2.04492 2.15039C1.91602 2.29102 1.72266 2.58398 1.62305 2.80078L1.43555 3.19336V15.0059V26.8125L1.65234 27.2578C1.91602 27.791 2.25586 28.1191 2.80078 28.377L3.19336 28.5645H10.3125H17.4316L17.8242 28.377C18.3691 28.1191 18.709 27.791 18.9727 27.2578L19.1895 26.8125L19.207 25.5293C19.2305 24.0234 19.2012 23.8535 18.8262 23.4375C18.2695 22.8164 17.4434 22.8047 16.8457 23.4082C16.459 23.7891 16.4063 23.9883 16.4063 24.9844V25.7812H10.3125H4.21875V15V4.21875H10.3125H16.4063V5.01562C16.4063 6.01172 16.459 6.21094 16.8457 6.5918C17.4434 7.19531 18.2695 7.18359 18.8262 6.5625C19.2012 6.14648 19.2305 5.97656 19.207 4.47656L19.1895 3.19336L19.002 2.80078C18.7441 2.25586 18.416 1.91602 17.8828 1.65234L17.4375 1.43555L10.4355 1.42383C6.32813 1.41797 3.33398 1.44141 3.19922 1.4707Z"
        fill="#333333"
      />
      <path
        d="M21.2988 8.43164C20.8125 8.66016 20.502 9.15234 20.502 9.69727C20.502 10.2422 20.5781 10.3535 22.2539 12.0234L23.8184 13.5938H16.0312C10.0898 13.5938 8.19141 13.6113 8.00391 13.6641C7.5 13.8164 7.03125 14.4609 7.03125 15C7.03125 15.5391 7.5 16.1836 8.00391 16.3359C8.19141 16.3887 10.0898 16.4062 16.0312 16.4062H23.8184L22.2539 17.9707C20.5781 19.6465 20.502 19.7578 20.502 20.3027C20.502 20.8477 20.8125 21.3398 21.2988 21.5684C21.6094 21.7148 22.1602 21.7148 22.4707 21.5684C22.6289 21.4922 23.6367 20.5195 25.582 18.5684C28.6348 15.498 28.5938 15.5449 28.5938 15C28.5938 14.4551 28.6348 14.502 25.582 11.4316C23.6367 9.48047 22.6289 8.50781 22.4707 8.43164C22.1602 8.28516 21.6094 8.28516 21.2988 8.43164Z"
        fill="#FF6633"
      />
    </svg>
  );
};
