import React from 'react';
import { IconTypes } from '../../../models/Icons/IconModels';

export const IconStar = ({ active = false }: IconTypes) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2422 0.0585938C23.0566 0.078125 22.4805 0.146484 21.9727 0.195312C18.75 0.546875 15.1855 1.75781 12.3047 3.47656C8.64258 5.66406 5.66406 8.64258 3.47656 12.3047C1.76758 15.1562 0.634766 18.4766 0.175781 21.9727C0.0195313 23.1738 0.0195313 26.8262 0.175781 28.0273C0.976562 34.1113 3.65234 39.2578 8.18359 43.4668C10.6836 45.8008 13.7988 47.6172 17.1875 48.7305C19.8633 49.6191 21.9824 49.9414 25 49.9414C27.2266 49.9414 28.2129 49.8535 30.1758 49.4531C36.9043 48.0762 42.9102 43.7598 46.5234 37.6953C48.2324 34.8438 49.3652 31.5234 49.8242 28.0273C49.9805 26.8262 49.9805 23.1738 49.8242 21.9727C49.3652 18.4766 48.2324 15.1562 46.5234 12.3047C44.3359 8.64258 41.3574 5.66406 37.6953 3.47656C34.873 1.78711 31.5039 0.634766 28.125 0.195312C27.2656 0.078125 23.8477 -0.00976562 23.2422 0.0585938ZM27.6855 12.5488C29.1309 15.4785 30.3613 17.8711 30.4199 17.8711C30.5469 17.8711 41.9141 19.5215 42.0312 19.5605C42.0898 19.5801 37.0996 24.5117 33.9453 27.5391L33.5742 27.9102L34.5703 33.7305C35.1172 36.9336 35.5469 39.5801 35.5176 39.6094C35.498 39.6387 33.125 38.4277 30.2637 36.9238C27.3926 35.4102 25.0293 34.1797 25 34.1797C24.9707 34.1797 22.6074 35.4102 19.7363 36.9238C16.875 38.4277 14.502 39.6387 14.4824 39.6094C14.4531 39.5801 14.8828 36.9336 15.4297 33.7305L16.4258 27.9102L16.0547 27.5391C12.9004 24.5117 7.91016 19.5801 7.96875 19.5605C8.08594 19.5215 19.4531 17.8711 19.5801 17.8711C19.6387 17.8711 20.8691 15.4785 22.3145 12.5488C23.7598 9.61914 24.9609 7.22656 25 7.22656C25.0391 7.22656 26.2402 9.61914 27.6855 12.5488Z"
        fill={active ? '#FF6633' : '#7c7c7c'}
      />
      <path
        d="M23.291 17.1875C21.8554 20.0684 21.6015 20.5078 21.3867 20.5469C21.0254 20.625 14.375 21.5821 14.2383 21.5821C14.1699 21.5821 15.3418 22.7832 16.8457 24.2481L19.5703 26.9141L18.9453 30.5469C18.6035 32.5489 18.3496 34.209 18.3789 34.2383C18.4179 34.2676 19.9121 33.5157 21.7187 32.5684L25 30.8399L28.2812 32.5684C30.0879 33.5157 31.582 34.2676 31.6211 34.2383C31.6504 34.209 31.3965 32.5489 31.0547 30.5469L30.4297 26.9141L33.1543 24.2481C34.6582 22.7832 35.8301 21.5821 35.7617 21.5821C35.625 21.5821 28.9746 20.625 28.6133 20.5469C28.3984 20.5078 28.1445 20.0684 26.709 17.1875C25.8008 15.3614 25.0293 13.8672 25 13.8672C24.9707 13.8672 24.1992 15.3614 23.291 17.1875Z"
        fill={active ? '#FF6633' : '#fff'}
      />
    </svg>
  );
};
