import React from 'react';
import { IconTypes } from '../../../../models/Icons/IconModels';

export const IconPhone = ({ active = false }: IconTypes) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.61915 0.0439463C2.25587 0.123048 2.18849 0.175782 1.2422 1.125C0.416024 1.94824 0.351571 2.02149 0.254891 2.22656C0.0937587 2.56641 0.0556727 2.74805 0.0380946 3.22852C0.0205165 3.71192 0.0586024 4.03125 0.205087 4.58789C1.08692 7.93652 5.03028 12.3428 8.80372 14.1943C9.92286 14.7422 10.7695 14.9824 11.6016 14.9854C12.1113 14.9854 12.3691 14.9326 12.7236 14.7598C12.9522 14.6484 13.0107 14.5928 13.834 13.7725C14.7539 12.8525 14.8066 12.7852 14.9238 12.3955C14.9854 12.1904 14.9854 11.8037 14.9238 11.5986C14.8184 11.2471 14.7744 11.1943 13.6113 10.0254C12.999 9.40723 12.416 8.84473 12.3193 8.77441C11.7393 8.36133 10.9922 8.41992 10.5059 8.91504C10.3594 9.06445 10.3008 9.15234 10.2012 9.38965C10.1309 9.54785 10.04 9.72656 9.9961 9.78516C9.71485 10.166 9.17579 10.4063 8.71583 10.3594C8.03028 10.2861 7.00782 9.66504 6.14063 8.79785C5.09181 7.74902 4.47364 6.54199 4.62306 5.83887C4.69337 5.51074 4.92481 5.16797 5.19727 4.98633C5.26173 4.93945 5.40235 4.86621 5.50782 4.82227C5.81251 4.69336 5.88575 4.64942 6.02931 4.52637C6.51563 4.10156 6.6211 3.30469 6.26661 2.73926C6.16407 2.57227 4.14259 0.530274 3.89649 0.339845C3.7002 0.190431 3.44239 0.0732431 3.21095 0.0322275C2.99122 -0.00878811 2.84474 -0.00585842 2.61915 0.0439463Z"
        fill="#7C7C7C"
        fillOpacity="0.2"
      />
    </svg>
  );
};
