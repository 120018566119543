import React from 'react';

export const IconEye = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1196 12.7465C9.37337 12.7465 8.72966 12.4759 8.18848 11.9347C7.6473 11.3936 7.37671 10.747 7.37671 9.99505C7.37671 9.24309 7.6473 8.59938 8.18848 8.0639C8.72966 7.52272 9.37337 7.25213 10.1196 7.25213C10.8659 7.25213 11.4897 7.51987 11.991 8.05535C12.498 8.58514 12.7515 9.2317 12.7515 9.99505C12.7515 10.7584 12.5008 11.4078 11.9995 11.9433C11.4982 12.4788 10.8716 12.7465 10.1196 12.7465ZM3.75366 9.96087C3.75366 10.6217 4.04419 11.1771 4.62524 11.6271C8.15147 14.6919 11.7773 14.6919 15.5029 11.6271C16.0099 11.1201 16.2577 10.579 16.2463 10.0036C16.2463 9.64471 16.0811 9.27443 15.7507 8.89276C15.6368 8.76743 15.4688 8.59369 15.2466 8.37152C13.492 6.91887 11.6919 6.22959 9.84619 6.30365C8.43912 6.36061 7.03777 6.83912 5.64209 7.73919C5.29459 7.96706 4.95565 8.22055 4.62524 8.49969C4.40877 8.71616 4.25212 8.87851 4.15527 8.98675C3.88753 9.30007 3.75366 9.62477 3.75366 9.96087ZM1.62598 9.94378C1.62598 8.98675 1.95923 8.17214 2.62573 7.49993V7.46575C2.64282 7.40309 2.68555 7.37176 2.75391 7.37176C4.99269 5.01905 7.34821 3.79713 9.82056 3.70599C11.3985 3.64902 13.0021 4.13893 14.6313 5.17572C15.52 5.74538 16.3916 6.47739 17.2461 7.37176C17.6278 7.69647 17.9325 8.09238 18.1604 8.5595C18.3883 9.02663 18.5022 9.50799 18.5022 10.0036C18.5022 10.9321 18.1262 11.8464 17.3743 12.7465C15.6596 14.3758 13.9592 15.4581 12.2729 15.9936C11.6235 16.193 10.9001 16.3041 10.1025 16.3268C9.30501 16.3439 8.52173 16.2385 7.75269 16.0107C6.97225 15.7828 6.1377 15.3727 5.24902 14.7802C4.36035 14.1878 3.48593 13.4273 2.62573 12.4987V12.3791C1.95923 11.7126 1.62598 10.9008 1.62598 9.94378Z"
        fill="currentColor"
      />
    </svg>
  );
};
