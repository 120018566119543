import React from 'react';
import { IconTypes } from '../../../../models/Icons/IconModels';

export const IconAssistant = ({ active = false }: IconTypes) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7695 3.63284C9.39259 4.1719 5.81837 6.97268 4.28908 11.0625L4.06056 11.6719L3.69728 11.9004C1.87501 13.0547 0.609388 14.8887 0.140638 17.0567C-0.00584596 17.7364 -0.0117053 19.2832 0.134779 19.9805C0.480482 21.627 1.15431 22.8867 2.31447 24.0586C3.4922 25.2481 4.7754 25.9395 6.44533 26.291C6.90822 26.3907 7.47658 26.3965 15 26.3965C24 26.3965 23.4024 26.4199 24.6797 25.9805C25.834 25.5821 26.707 25.0313 27.6328 24.1114C28.8164 22.9453 29.5195 21.6446 29.8652 19.9805C30.0117 19.2832 30.0059 17.7364 29.8594 17.0567C29.625 15.9727 29.25 15.0879 28.6406 14.168C28.1602 13.4532 27.1524 12.4453 26.4434 11.9883C25.9102 11.6426 25.9043 11.6367 25.7754 11.2559C24.4805 7.40628 21.0176 4.45901 16.9336 3.74417C16.1602 3.60354 14.4727 3.55081 13.7695 3.63284ZM16.834 5.50784C18.8027 5.89456 20.5899 6.93753 21.9492 8.49026C22.8281 9.49807 23.7715 11.0742 24.2578 12.3692C24.4277 12.8203 24.4746 12.8848 24.6621 12.9785C25.0137 13.1426 25.7871 13.6699 26.1914 14.0157C27.1231 14.8125 27.8262 15.9844 28.1016 17.1973C28.2598 17.8711 28.2481 19.3067 28.0781 19.9219C27.7559 21.1114 27.2227 22.0606 26.4317 22.8574C25.6289 23.6543 24.6738 24.1934 23.4668 24.5157C22.9981 24.6387 22.9863 24.6387 15 24.6387C7.01369 24.6387 7.00197 24.6387 6.53322 24.5157C5.32619 24.1934 4.37111 23.6543 3.56837 22.8574C2.77736 22.0664 2.24415 21.1172 1.91603 19.9219C1.82228 19.5586 1.79884 19.3125 1.79884 18.5157C1.79884 17.4492 1.86915 17.0684 2.22658 16.2129C2.72462 15.0117 3.8965 13.7696 5.20314 13.0606C5.51954 12.8848 5.57814 12.8321 5.63087 12.627C5.7715 12.0938 6.29298 10.875 6.63869 10.2715C7.18361 9.31643 7.62892 8.72464 8.32033 8.02737C9.81447 6.52151 11.584 5.66604 13.7695 5.39651C14.4961 5.30276 16.1074 5.36135 16.834 5.50784Z"
        fill={active ? '#fff' : '#333333'}
      />
      <path
        d="M12.334 7.18945C11.5137 7.36523 10.8047 7.75195 10.1719 8.36719C9.26367 9.25195 8.84766 10.3066 8.84766 11.7188V12.3633H8.29102C7.2832 12.3633 6.69727 12.5742 6.11719 13.1543C5.73633 13.5352 5.50195 13.9512 5.39648 14.4609C5.31445 14.8652 5.31445 16.8984 5.39648 17.2969C5.61328 18.3047 6.42188 19.1133 7.42969 19.3301C7.61133 19.3652 8.32617 19.3945 9.16992 19.3945H10.6055V15.3223C10.6055 12.5566 10.6289 11.1562 10.6699 10.9453C10.8809 9.94336 11.6953 9.12891 12.7031 8.91211C13.1191 8.82422 16.875 8.82422 17.2969 8.91211C18.2988 9.12305 19.1133 9.9375 19.3301 10.9453C19.377 11.1621 19.3945 12.6504 19.3945 15.8555C19.3945 20.3496 19.3945 20.4609 19.2773 20.6836C19.2012 20.8359 19.0781 20.959 18.9258 21.0352C18.709 21.1465 18.5859 21.1523 16.4062 21.1523H14.1211V22.0312V22.9102H16.4355C17.9121 22.9102 18.8555 22.8867 19.0547 22.8457C19.8047 22.6875 20.4668 22.1836 20.8184 21.5215C21.0703 21.0234 21.1523 20.6543 21.1523 19.9512V19.3945H21.709C22.6641 19.3945 23.2559 19.1953 23.8242 18.6797C24.1699 18.3633 24.5039 17.7656 24.6035 17.2969C24.6855 16.8926 24.6855 14.8594 24.6035 14.4609C24.4922 13.957 24.2578 13.5352 23.877 13.1484C23.3086 12.5742 22.7227 12.3633 21.709 12.3633H21.1523V11.7188C21.1523 10.6465 20.9355 9.86719 20.4199 9.08203C19.7812 8.10352 18.7734 7.41797 17.625 7.18359C16.9863 7.05469 12.9492 7.05469 12.334 7.18945ZM8.84766 15.8789V17.6367H8.31445C7.86914 17.6367 7.74023 17.6133 7.54688 17.5078C7.13672 17.2734 7.08984 17.1035 7.08984 15.8672C7.0957 14.6426 7.13672 14.502 7.53516 14.2617C7.73438 14.1387 7.83984 14.1211 8.30859 14.1211H8.84766V15.8789ZM22.4414 14.2383C22.8516 14.4492 22.9102 14.6484 22.9102 15.8789C22.9102 16.8223 22.8984 16.9629 22.793 17.168C22.6113 17.5195 22.3359 17.6367 21.6797 17.6367H21.1523V15.8789V14.1211H21.6797C22.0957 14.1211 22.2598 14.1445 22.4414 14.2383Z"
        fill={active ? '#fff' : '#ff6633'}
      />
    </svg>
  );
};
