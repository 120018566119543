import React from 'react';
import styles from './VersionItem.module.scss';

type VersionItemTypes = {
  version: string;
};

export const VersionItem = () => {
  return <>version</>;
};
