import React from 'react';
import { IconTypes } from '../../../../models/Icons/IconModels';

export const IconCross = ({ active = false, disabled = false }: IconTypes) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82619 0.0323277C5.93264 0.117289 4.93361 0.401468 4.16018 0.791117C3.36037 1.19541 2.52248 1.83409 1.94826 2.47862C0.823262 3.74424 0.184591 5.20616 0.0293171 6.87022C-0.0908 8.17686 0.19045 9.65049 0.791036 10.8399C1.19533 11.6398 1.834 12.4776 2.47854 13.0519C3.74416 14.1769 5.20607 14.8155 6.87014 14.9708C8.17678 15.0909 9.65041 14.8097 10.8399 14.2091C11.6397 13.8048 12.4776 13.1661 13.0518 12.5216C14.1768 11.256 14.8155 9.79405 14.9707 8.12998C15.0908 6.82334 14.8096 5.34971 14.209 4.16026C13.8047 3.36045 13.166 2.52256 12.5215 1.94834C11.2559 0.823343 9.79397 0.184671 8.1299 0.029398C7.76369 -0.0028286 7.1924 -0.0028286 6.82619 0.0323277ZM6.26076 5.01573L7.50002 6.25498L8.74514 5.00987L9.99025 3.76475L10.6113 4.38877L11.2354 5.00987L9.99025 6.25498L8.74514 7.5001L9.99025 8.74522L11.2354 9.99034L10.6113 10.6114L9.99025 11.2355L8.74514 9.99034L7.50002 8.74522L6.2549 9.99034L5.00979 11.2355L4.38869 10.6114L3.76467 9.99034L5.00979 8.74522L6.2549 7.5001L5.00979 6.25498L3.76467 5.00987L4.3799 4.39463C4.71682 4.05772 5.001 3.7794 5.00979 3.7794C5.01857 3.7794 5.58107 4.33604 6.26076 5.01573Z"
        fill={disabled ? 'rgba(124, 124, 124, 0.2)' : '#E33F3D'}
      />
    </svg>
  );
};
