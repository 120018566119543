import React from 'react';
import { IconTypes } from '../../../models/Icons/IconModels';

export const IconEditColored = ({ active = false, size = 10 }: IconTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.34961 0.03125C8.27539 0.046875 8.12891 0.0996094 8.02734 0.148438L7.8418 0.238281L5.36914 2.71289L2.89453 5.18555L2.52148 6.30859C2.30859 6.95312 2.14844 7.46289 2.14844 7.50391C2.14844 7.69727 2.30273 7.85156 2.49414 7.85156C2.53906 7.85156 3.02734 7.69922 3.69141 7.47852L4.81445 7.10547L7.28711 4.63086L9.76172 2.1582L9.85156 1.97266C10.0488 1.5625 10.0488 1.16992 9.84961 0.761719C9.77539 0.607422 9.73242 0.548828 9.5918 0.408203C9.45117 0.265625 9.39453 0.224609 9.23828 0.150391C8.93945 0.0078125 8.6543 -0.03125 8.34961 0.03125ZM8.90625 0.783203C9.03516 0.845703 9.14649 0.951172 9.21484 1.08398C9.25977 1.16992 9.26758 1.20508 9.26758 1.36719C9.26758 1.52539 9.25977 1.56836 9.2207 1.65039C9.18164 1.72852 8.68555 2.23438 6.8125 4.11133L4.45313 6.47461L3.76563 6.70312C3.38867 6.83008 3.07617 6.92969 3.07227 6.92773C3.07031 6.92383 3.16992 6.61328 3.29297 6.23828L3.51953 5.55664L5.82813 3.24609C7.0957 1.97461 8.16797 0.908203 8.20899 0.875C8.25 0.841797 8.33399 0.792969 8.39453 0.767578C8.54688 0.705078 8.75195 0.710938 8.90625 0.783203Z"
        fill="#FF6633"
      />
      <path
        d="M0.792944 0.751948C0.517554 0.82812 0.261694 1.0332 0.132788 1.28125C-0.00979011 1.55273 -0.00197762 1.25976 0.00388176 5.41406L0.00974113 9.15039L0.0605224 9.28124C0.0878661 9.35351 0.140601 9.45507 0.173804 9.50976C0.322241 9.74023 0.61521 9.93554 0.878882 9.98046C0.968726 9.99609 1.98826 9.99999 4.36521 9.99609L7.72459 9.99023L7.85935 9.93749C8.21482 9.79882 8.47068 9.5039 8.5449 9.14453C8.57029 9.02343 8.57419 8.73632 8.57029 7.12109L8.56443 5.24218L8.51755 5.17187C8.4199 5.03124 8.24998 4.96874 8.09958 5.01757C8.01169 5.04687 7.90037 5.16992 7.87302 5.26757C7.85935 5.3164 7.85154 5.9414 7.85154 7.17187C7.85154 8.41601 7.84568 9.01953 7.83005 9.05664C7.80466 9.12304 7.72263 9.21289 7.65232 9.24999C7.6074 9.27343 7.09959 9.27734 4.28513 9.27734H0.970679L0.890601 9.23046C0.845679 9.20507 0.790991 9.14843 0.765601 9.10546L0.722632 9.02734L0.726538 5.33789L0.732397 1.64648L0.789038 1.57421C0.820288 1.5332 0.880835 1.48242 0.925757 1.46289C1.00193 1.42773 1.09958 1.42578 3.21677 1.42578C4.73044 1.42578 5.44529 1.41992 5.4824 1.40429C5.61326 1.35546 5.70505 1.2207 5.70701 1.07617C5.70701 0.945308 5.64646 0.83398 5.54099 0.77148L5.45505 0.722651L3.17185 0.724605C1.31638 0.726558 0.871069 0.730464 0.792944 0.751948Z"
        fill="#333333"
      />
    </svg>
  );
};
