import React from 'react';
import { IconTypes } from '../../../../models/Icons/IconModels';

export const IconCheck = ({ disabled = false }: IconTypes) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82619 0.0323277C5.93264 0.117289 4.93361 0.401468 4.16018 0.791117C3.36037 1.19541 2.52248 1.83409 1.94826 2.47862C0.823262 3.74424 0.184591 5.20616 0.0293171 6.87022C-0.0908 8.17686 0.19045 9.65049 0.791036 10.8399C1.19533 11.6398 1.834 12.4776 2.47854 13.0519C3.74416 14.1769 5.20607 14.8155 6.87014 14.9708C8.17678 15.0909 9.65041 14.8097 10.8399 14.2091C11.6397 13.8048 12.4776 13.1661 13.0518 12.5216C14.1768 11.256 14.8155 9.79405 14.9707 8.12998C15.0908 6.82334 14.8096 5.34971 14.209 4.16026C13.8047 3.36045 13.166 2.52256 12.5215 1.94834C11.2559 0.823343 9.79397 0.184671 8.1299 0.029398C7.76369 -0.0028286 7.1924 -0.0028286 6.82619 0.0323277ZM11.458 5.30577C12.0322 5.93272 12.0469 5.95323 11.9942 6.00303C11.9619 6.03233 10.7725 7.11631 9.34572 8.40831C7.91897 9.70323 6.71486 10.796 6.67092 10.837L6.58889 10.9103L4.95119 9.27256L3.31057 7.63194L3.93166 7.01084L4.55568 6.38682L5.59865 7.42979C6.17287 8.00401 6.65627 8.4669 6.66799 8.45811C6.68264 8.44932 7.63186 7.59092 8.77443 6.55088C9.91994 5.51084 10.8574 4.65831 10.8604 4.65831C10.8633 4.65831 11.1328 4.94834 11.458 5.30577Z"
        fill={disabled ? 'rgba(124, 124, 124, 0.2)' : '#24B26D'}
      />
    </svg>
  );
};
